<template>
  <button
    :class="classes"
    :disabled="disabled"
    @click.prevent="$emit('click')"
  >
    <i
      v-if="icon !== ''"
      :class="icon"
      :color="color"
      size="20"
    />
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BasePaginationButton',
  props: {
    active: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    icon: {
      type: String,
      required: false,
      default: () => {
        return '';
      },
    },
  },
  computed: {
    classes() {
      return {
        'pagination-button': true,
        default: !this.active,
        active: this.active,
        disabled: this.disabled,
      };
    },
    color() {
      if (this.disabled) {
        return '#c9cbd4';
      }
      return '#374458';
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-button {
  height: 30px;
  padding: 0 10px;
  font-size: .7em;
  font-weight: 600;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.default {
  color: $simpatra-blue;
  background: $grey;
  border: $grey;
  &:hover {
    background: $dark-grey;
  }
}
.active {
  color: $white;
  background: $simpatra-blue;
  border: $border-blue;
}
.disabled {
  color: $dark-grey;
  &:hover {
    cursor: default;
    background: $grey;
  }
}
</style>
