<template>
  <div class="pagination">
    <span class="button-wrapper previous">
      <BasePaginationButton
        icon="el-icon-back"
        :disabled="prevDisabled"
        @click="$emit('previous')"
      />
    </span>
    <!--
    We always want a button for the first page
    -->
    <span class="button-wrapper">
      <BasePaginationButton
        :active="buttons[0].page === currentPage"
        @click="$emit('page-clicked', buttons[0].page)"
      >
        {{ buttons[0].page }}
      </BasePaginationButton>
    </span>
    <span
      v-for="b in currentButtons"
      :key="b.page"
      class="button-wrapper proximity-buttons"
    >
      <BasePaginationButton
        :active="b.page === currentPage"
        @click="$emit('page-clicked', b.page)"
      >
        {{ b.page }}
      </BasePaginationButton>
    </span>
    <!--
    We always want a button for the last page
    -->
    <span class="button-wrapper">
      <BasePaginationButton
        :active="buttons[buttons.length - 1].page === currentPage"
        @click="$emit('page-clicked', buttons[buttons.length - 1].page)"
      >
        {{ buttons[buttons.length - 1].page }}
      </BasePaginationButton>
    </span>
    <span class="button-wrapper next">
      <BasePaginationButton
        icon="el-icon-right"
        :disabled="nextDisabled"
        @click="$emit('next')"
      />
    </span>
  </div>
</template>

<script>
import BasePaginationButton from '@/components/common/BasePaginationButton.vue';

export default {
  name: 'BasePagination',
  components: {
    BasePaginationButton,
  },
  props: {
    pages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    buttons() {
      const buttons = [];
      for (let i = 0; i < this.pages; i++) {
        buttons.push({
          page: i + 1,
        });
      }
      return buttons;
    },
    currentButtons() {
      if (this.buttons.length === 2) {
        return [];
      }
      if (this.buttons.length === 3) {
        const a = 1;
        const b = 2;
        return this.buttons.slice(a, b);
      }
      if (this.buttons.length === 4) {
        const a = 1;
        const b = 3;
        return this.buttons.slice(a, b);
      }
      if (this.currentPage <= 3) {
        const a = 1;
        const b = 4;
        return this.buttons.slice(a, b);
      }
      if (this.currentPage >= this.pages - 2) {
        const a = this.pages - 4;
        const b = this.pages - 1;
        return this.buttons.slice(a, b);
      }
      const a = this.currentPage - 2;
      const b = this.currentPage + 1;
      return this.buttons.slice(a, b);
    },
    prevDisabled() {
      return this.currentPage <= 1;
    },
    nextDisabled() {
      return this.currentPage >= this.pages;
    },
  },
};
</script>

<style lang="scss" scoped>
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-wrapper {
    margin: 0 3px;
  }
  @media screen and (max-width: 340px) {
    .proximity-buttons {
      display: grid;
      transform: scale(.8);
    }
  }
</style>
