<template>
  <input
    ref="input"
    v-model.number="inputValue"
    :maxlength="maxLengthValue"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @keydown="$emit('keydown')"
    @keypress="$emit('keypress')"
    @keyup="$emit('keyup')"
  />
</template>

<script>
export default {
  name: "BaseNumberInput",
  props: {
    value: {
      type: Number,
      required: true,
    },
    maxLengthValue: {
      type: Number,
      default: () => {
        return 20;
      },
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(inputValue) {
        this.$emit("input", inputValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  width: 54px;
  height: 34px;
  margin: 0;
  padding: 0;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background-color: #fff;
  text-transform: inherit;
  text-align: center;
  font-size: 16px;
  &::placeholder {
    color: rgb(196, 196, 196);
  }
  &:hover {
    border: 1px solid rgb(196, 196, 196);
  }
  &:focus {
    outline: none;
    border: 1px solid $orange;
  }
}
</style>
